<template>
    <!-- :default-expand-all="true" :default-expanded-keys="['0','4528']" -->
    <div class="wraper" v-wechat-title="$route.meta.title"> 
        <div>
            <img style="width:1210px;height:70px;" src="/assets/authorize/doc.png" />
        </div>
        <el-container >  
            <el-aside width="250px" >
                <el-tree :data="treeData" 
                         :props="defaultProps" 
                         @node-click="handleNodeClick" 
                         node-key="id"            
                         :default-expanded-keys="['0','4528']"
                         v-loading="loadingtree"
                         element-loading-text="加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(255, 251, 255, 0.8)"               
                         :style="{background:'#f8f8f9',overflow:'auto',height: scrollerHeight}"
                         highlight-current
                         ref="eltree"
                         :expand-on-click-node="false">
                    <template v-slot="{node,data}">                                     
                        <span v-if="data.id ==0 "  >
                          <span  style="color:#F56C6C;font-size:14px;"><i class="iconfont icon-shujuku" style="color:#F56C6C;font-size:18px;"></i> {{ node.label}} </span>
                        </span>                  
                        <span v-else  > 
                            <span style="color:#2b85e4;font-size:12px;"><i class="iconfont icon-wenjianmulu" style="margin-right:5px;"></i>{{ node.label}} </span>
                        </span>
                    </template>
                </el-tree>
            </el-aside>
            <el-main  >          
 
                <div style=" height:30px;line-height:30px;padding-top:8px;font-size:12px;margin-top:-18px;padding-left:5px;border-left:3px solid #409EFF;background:#f8f8f9" >
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/member/basic_info' }"><el-link style="color:#409EFF">会员中心</el-link></el-breadcrumb-item>
                        <el-breadcrumb-item v-for="(item,index) in pathArr" :key="index" >
                            <span style="color:#67C23A"   >{{item.name}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div> 
                <div :style="{'border':'1px solid #ccc','height':divHeight,'margin-top': '5px','overflow-y':'auto'}">
                    <div style="border-bottom:1px solid #ccc;line-height:30px; height:40px;padding-top:5px;">
                        <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link :disabled="addBool" @click="addFolder" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;">新增目录</span></el-link>
                        <i class="iconfont icon-bianji" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:20px;"></i>
                        <el-link :disabled="editBool" @click="editFolder" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;">编辑目录</span></el-link>
                        <i class="iconfont icon-shanchu" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:20px;"></i>
                        <el-link :disabled="delBool" @click="delFolder" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;">删除目录</span></el-link>
                        <i class="el-icon-setting" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:20px;"></i>
                        <el-link @click="setPriv" :underline="false" style="margin-top:-2px;"><span style="font-size:13px;">设置目录权限</span></el-link>
                        <Upload
                            ref="upload"
                            name="upfile"
                            :data="uploadData"
                            :show-upload-list="false"
                            :before-upload="beforeImgFile"
                            :on-success="successImgFile"
                            :on-progress="handleProgress"
                            :accept="Accept"                           
                            :format="['jpg','jpeg','png','pdf','mp4']"
                            :max-size="20480"
                            :on-format-error="handleFormatError"
                            :on-exceeded-size="handleMaxSize"
                            :action="uploadUrl"
                            style="display: inline-block;width:70px;margin-right:10px;vertical-align:middle; ">                           
                            <div style="display:flex;width:140px;margin-top:-2px;margin-right:15px;">
                                <i class="iconfont icon-shangchuan1" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:20px;"></i>
                                <el-link :underline="false"  >
                                   <span style="font-size:13px;margin-right:15px;color:#E6A23C">上传文件</span>
                                </el-link>                                  
                            </div>                                                   
                        </Upload>
                        <span   style="margin-top:-2px;margin-left:40px;">文件搜索</span>
                        <el-input @change="fileSearch" v-model="srchFile" placeholder="请输入搜索文件名" style="width: 140px;margin-left:5px;" size="mini">
                            <!-- <template slot="append" ><i class="el-icon-search"></i></template> -->
                        </el-input> 
                        <span   style="margin-top:-2px;margin-left:20px;margin-right:5px;">排序</span>
                        <Select v-model="fileSort" style="width:120px;" size="small" @on-change="fileSortBy('')">
                            <Option   v-for="item in sortList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                        <el-link :underline="false" v-show="sortFlag " @click="ascSort">
                            <i class="iconfont icon-paixu-shengxu" style="color:#409EFF;font-size:14px; margin-left:10px;"></i>
                        </el-link>
                        <el-link :underline="false" v-show="!sortFlag " @click="descSort">
                            <i class="iconfont icon-paixu-jiangxu" style="color:#409EFF;font-size:14px; margin-left:10px;"></i>
                        </el-link>
                    </div>
                    <div style="border-bottom:1px solid #ccc;line-height:30px; height:40px;padding-top:5px;display:flex;">
                        <div style="margin-left:2px; ">
                            <el-checkbox  style="height:30px; width:110px;text-align:left;padding-left:8px;" @change="allSelect" true-label="Y" false-label="N" v-model="checkAll"><span style="font-size:12px;">本页全选文件</span></el-checkbox>
                        </div>
                        <div style="margin-left:25px; ">
                            <span style="margin-right:8px;">移动到文件夹</span>
                            <el-select
                                width="140px"
                                size="mini"
                                ref="select"
                                placeholder="移动到......"
                                v-model="moveVal">
                                <el-option value="" style="height:200px; overflow-y:auto">
                                    <el-tree
                                        ref="treeOption"
                                        default-expand-all
                                        highlight-current
                                        node-key="id"
                                        :data="treeData"
                                        :props="defaultProps"
                                        @node-click="moveSel"
                                    ></el-tree>
                                </el-option>
                            </el-select>
                        </div>
                        <div style="margin-left:25px; margin-top:-5px; ">
                            <i class="iconfont icon-shanchu1" style="margin-right:5px;color:#409eff"></i>
                            <el-button type="text"   @click="delFiles"  >删除选中</el-button>
                        </div>
                        <div style="margin-left:25px;margin-top:-5px; ">
                            <i class="iconfont icon-xiazai" style="margin-right:5px;color:#409eff"></i>
                            <el-button  :loading="downing" type="text"   @click="bulkDownload" >批量下载</el-button>
                        </div>
                    </div>
                    <!-- 目录集 -->
                    <div style="display:flex;flex-wrap:wrap">
                        <div v-for="(item,index) in dirList" :key="index" style="display:flex;flex-direction:column">
                            <div class="dircss" >
                                <el-link :underline="false" @click="dirClick(item.CODE,item.CNAME,item.IDSEQ,item.ICON,item.PARENTID)" style="padding-top:10px; ">
                                    <i class="iconfont icon-select-file" style="font-size:40px;"></i>
                                </el-link>
                            </div>
                            <div :title="item.CNAME" class="divtext">{{item.CNAME}}</div>
                        </div>
                    </div>
                    <!-- 文件集 -->
                    <div style="display:flex;flex-wrap:wrap">
                        <div v-for="(item,index) in fileList" :key="index" style="display:flex;flex-direction:column">
                            <div :class="fileArr.indexOf(item.PKSEQ)>-1?'dircss2':'dircss'"  >
                                <el-link :underline="false" @click.native="fileClick(item.PKSEQ,item.FILEPATH)">
                                    <span v-if="item.FILETY==='pdf'">
                                       <el-image   :src="pdfImg" fit="scale-down" style="width:88px; max-height:78px;" ></el-image>
                                       <el-link :underline="false" class="picsrch" @click.native="pdfPreview(item.FILEPATH)">      
                                            <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                        </el-link> 
                                        <el-link :underline="false" class="fileset" @click.native="filePrivSet(item)">      
                                            <i class="iconfont icon-xitongguanli" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                        </el-link> 
                                       <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div> 
                                    </span>
                                    <span v-else-if="item.FILETY==='mp4'">
                                       <el-image   :src="mp4Img" fit="scale-down" style="width:80px; max-height:70px;" ></el-image>
                                       <el-link :underline="false" class="picsrch" @click.native="mp4Preview(item.FILEPATH)">      
                                            <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                        </el-link> 
                                        <el-link :underline="false" class="fileset" @click.native="filePrivSet(item )">      
                                            <i class="iconfont icon-xitongguanli" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                        </el-link> 
                                       <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div> 
                                    </span>
                                    <span v-else>
                                        <el-image   :src="item.FILEPATH" fit="fill" style="width:88px; max-height:78px;" ></el-image>
                                        <el-link :underline="false" class="picsrch"> 
                                            <el-tooltip  effect="light"  placement="right" style="margin:4px;cursor:pointer" popper-class="atooltip">
                                                <i class="el-icon-search" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:99"></i>
                                                <template slot="content" >
                                                    <el-image   fit="scale-down" style="width:300px;height:300px; text-align:center; " :src="item.FILEPATH"></el-image>        
                                                </template>
                                            </el-tooltip>
                                        </el-link>
                                        <el-link :underline="false" class="fileset" @click.native="filePrivSet(item )">      
                                            <i class="iconfont icon-xitongguanli" style="font-size:20px;font-weight:600;background:#fff;color:#606266;z-index:9"></i>
                                        </el-link> 
                                        <div class="selected" v-if="fileArr.indexOf(item.PKSEQ)>-1"></div>
                                    </span>
                                </el-link>
                                
                            </div>
                            <div :title="item.FILENM" class="divtext">{{item.FILENM}}</div>
                        </div>
                    </div>  
                </div>          
                <div class="pageNext">
                    <div style="display:flex;justify-content:center;align-items:center;">  
                        <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
                        <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
                        <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                                <el-option 
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                        </el-select>
                        <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
                        <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
                        <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
                        <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;height:35px;">GO</el-button>
                    </div>
                </div> 
            </el-main>
            <Modal v-model="addModal"  :closable="false"  scrollable  width="380">
                <Form ref="addForm" :model="formCol" :label-width="100"   label-position="right" inline >
                    <FormItem label="上级目录编码"   >                                  
                        <Input type="text" disabled  v-model="formCol.PARENTNO"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="上级目录名称"   >                                  
                        <Input type="text" disabled  v-model="formCol.PARENTNM"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="本级目录编码"   >                                  
                        <Input type="text" disabled  v-model="formCol.CODE"  style="width:200px;" clearable/>
                    </FormItem>
                    <FormItem label="本级目录名称"   >                                  
                        <Input type="text"   v-model="formCol.CNAME"  style="width:200px;" clearable/>
                    </FormItem>
                </Form>                     
                <div slot="footer" style="height:24px;">
                    <Button type="primary" plain style="margin-right:25px;"  @click="addModal=false">取消</Button>
                    <Button type="primary"   @click="modal_ok">确定</Button>
                </div>
                <div slot="header" style="height:10px;line-height:10px">
                    <span style="font-weight:600;font-size:14px;color:#ccc">{{addtype==='create'?'新增目录':'修改目录'}}</span>
                </div>
            </Modal>  
            <Modal v-model="mp4Model"   scrollable  width="450" :footer-hide="true">
                <div style="width:400px;height:250px;border:1px solid #e8eaec;overflow-y:hidden;margin:20px auto;">  
                    <video-player   class="video-player vjs-custom-skin" 
                                    :playsline="false" 
                                    :options="playerOptions">
                    </video-player>
                </div>
            </Modal>  
        </el-container> 
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <el-dialog  :show-close="false" :visible.sync="setModal" width="600px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">设置目录权限</span>
            </div> 
            <div style="line-height:30px">目录名称：{{nodeLabel}}</div>
            <div style="line-height:30px">创建用户：{{adduser}}</div>
            <div style="line-height:30px">创建时间：{{adddttm}}</div>
            <div style="line-height:30px;display:flex">
              <div style="width:305px; ">  修改时间：{{upddttm}}</div>
              <div style=" width:60px; "><el-checkbox @change="allSel('S')" v-model="ISSEL" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
              <div style=" width:60px; "><el-checkbox @change="allSel('A')" v-model="ISADD" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
              <div style=" width:60px; "><el-checkbox @change="allSel('U')" v-model="ISUPD" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
              <div style=" width:60px; "><el-checkbox @change="allSel('D')" v-model="ISDEL" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
            </div>
            <div style="display:flex;line-height:30px;background:#f8f8ff">
                <div style="width:140px;">账号名称</div>
                <div style="width:160px;">权限部门</div>
                <div style="width:60px;text-align:center">浏览</div>
                <div style="width:60px;text-align:center">新增</div>
                <div style="width:60px;text-align:center">编辑</div>
                <div style="width:60px;text-align:center">删除</div>
            </div>
            <div style="height:400px;overflow-y:auto; line-height:30px;border-bottom:1px dashed #f8f8ff">
                <div v-for="(item,index) in empArr" :key="index" style="border-bottom:1px dashed #f8f8f9" > 
                    <div style="display:flex;flex-wrap:wrap">
                    <div style=" width:135px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;margin-right:5px;">{{item.USERNM}}</div>
                    <div style=" width:160px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap; ">{{item.DEPTNO}}</div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISSEL" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISADD" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISEDIT" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISDEL" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>   
                    </div>
                </div>
            </div>
            <div slot="footer" style="height:35px;">
                <Button type="primary" style="margin-right:25px;"  @click="setModal=false">取消</Button>
                <Button type="primary" @click="privOk"  >确定</Button>
            </div> 
        </el-dialog> 
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 设置文件权限 -->
        <el-dialog  :show-close="false" :visible.sync="fileModal" width="500px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">设置文件权限</span>
            </div> 
            <div style="line-height:30px">文件名称：{{file_Name}}</div>
            <div style="line-height:30px">创建用户：{{file_Adduser}}</div>
            <div style="line-height:30px">创建时间：{{file_Adddttm}}</div>
            <div style="line-height:30px;display:flex">
              <div style="width:305px; ">  修改时间：{{file_Upddttm}}</div>
              <div style=" width:60px; "><el-checkbox @change="allSel('S')" v-model="ISSEL" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
 
              <div style=" width:60px; "><el-checkbox @change="allSel('D')" v-model="ISDEL" style="margin-left:15px;" false-label="N"  true-label="Y"></el-checkbox></div>
            </div>
            <div style="display:flex;line-height:30px;background:#f8f8ff">
                <div style="width:140px;">账号名称</div>
                <div style="width:160px;">权限部门</div>
                <div style="width:60px;text-align:center">浏览</div>
                <div style="width:60px;text-align:center">删除</div>
            </div>
            <div style="height:400px;overflow-y:auto; line-height:30px;border-bottom:1px dashed #f8f8ff">
                <div v-for="(item,index) in empArr" :key="index" style="border-bottom:1px dashed #f8f8f9" > 
                    <div style="display:flex;flex-wrap:wrap">
                    <div style=" width:135px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;margin-right:5px;">{{item.USERNM}}</div>
                    <div style=" width:160px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap; ">{{item.DEPTNO}}</div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISSEL" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>
                    <div style=" width:60px; "><el-checkbox v-model="item.ISDEL" style="margin-left:20px;" false-label="N"  true-label="Y"></el-checkbox></div>   
                    </div>
                </div>
            </div>
            <div slot="footer" style="height:35px;">
                <Button type="primary" style="margin-right:25px;"  @click="fileExit">取消</Button>
                <Button type="primary" @click="fileOk"  >确定</Button>
            </div> 
        </el-dialog> 
    </div>  
</template>
<script>
import { getBaseData } from '../../api/user' 
import { paramBase,paramData ,getDate} from '../../api/Select'
// import { request} from '../../http/request'
import JSZip from 'jszip'
import axios from 'axios'
import FileSaver from 'file-saver'
const downloadFile = (url) => {
     return new Promise((resolve,reject)=>{
         axios({
             method:'get',
             url,
             responseType:'arraybuffer'
         }).then(data=>{
             resolve(data.data)
         }).catch(error=>{
             reject(error.toString())
         })
     })
}

//局部注册
export default {
    name:"doc_updown",
    data () {
        return {
            loadingtree:false,
            fileModal:false,
            promptWin:false,
            prompt:'',
            setModal:false,
            addModal:false,
            cfmBool:false, //设置文件权限
            addBool:false, //新增目录权限
            editBool:false, //编辑目录权限
            delBool:false, //删除目录权限
            file_Name:'',
            file_Adduser:'',
            file_Adddttm:'',
            file_Upddttm:'',
            treeCode:'',
            downing:false,
            videoFlag:false,
            videoUploadPercent:0,
            treeLabel:'',
            addtype:'create',
            formCol:{
                PARENTNO:'', //上级目录编码
                PARENTNM:'',//上级目录名称
                CODE:'', //本级目录编码
                CNAME:'', //本级目录名称
            },
            current:1,
            dataCount:0,
            srchFile:'',
            moveVal:'',
            rowSize:20,
            mp4Img:'/assets/basedict/video.png',
            pdfImg:'https://kreco.oss-accelerate.aliyuncs.com/manage/image/product/richie3898720211201160952.png',
            allData:[],
            fileArr:[], //点击后加入数组
            downArr:[], //批量下载数组
            sortFlag:true,
            uploadData:{}, //上传数据
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_telno')+'&password='+this.$cookies.get('v_enpwd'),
            Accept: "png,jpg,jpeg,mp4,pdf", //上传文件格式限制
            sortList: [
                {
                    value: 'time',
                    label: '按上传时间'
                },
                {
                    value: 'size',
                    label: '按文件大小'
                },
                {
                    value: 'name',
                    label: '按文件名称'
                }
            ],
            defaultProps: {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                parentid:'parentid',
                idseq:'idseq',
                label: 'label',
                adduser:'adduser',
                adddttm:'adddttm',
                upddttm:'upddttm'
            },
            ISSEL:'',
            ISADD:'',
            ISUPD:'',
            ISDEL:'',
            pathName:'资源库',
            fileSort:'time',
            goPage:'',
            options: [{
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 30,
                label: '30'
                } 
            ],
            LANG:'', 
            lock:'N',
            pathArr:[],
            treeid:'', // 
            idseq:'',
            fileName:'',
            nodeLabel:'',
            parentid:'',
            mp4Model:false,
            dirList:[], //目录集
            fileList:[], //文件集
            treeData:[] ,//  树数据    
            checkAll:'N',
            file_index:'',
            file_pkseq:'',
            file_path:'',
            file_idseq:'',
            file_lstseq:'',
            empArr:[],
            privArr:[], //权限集
            v_form:'base',
            disabledValues:['0'],
            pageSize:5, //证书每页显示数量
 
            v_user:this.$cookies.get('v_username'), 
            v_pwd:this.$cookies.get('v_password'),
            v_idseq :this.$cookies.get('v_idseq'),
            adduser:'',
            adddttm:'',
            upddttm:'',
            dirArr:[],
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.1], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: '' // url地址 /assets/authorize/tmp4.mp4
                }],
                poster: '', // 你的封面地址
                //width: '100%', // 播放器宽度
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        }
    },
    mounted () {   
        this.getPoint('0') //获得节点下的目录
        //用户权限表
        getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'frmidseq','116','v_sys_user_priv','').then(res=>{
            this.privArr=res.data.result
            if (this.privArr[0].ISCFM==='N'){ //审核
                this.cfmBool=true
            }if (this.privArr[0].ISADD==='N'){ //新增
                this.addBool=true
            }if (this.privArr[0].ISUPD==='N'){ //编辑
                this.editBool=true
            }if (this.privArr[0].ISDEL==='N'){ //删除
                this.delBool=true
            }
        })  
        //用户清单
        getBaseData(this.$store.state.nologinUrl, 'status','Y','','','v_sys_user','').then(res=>{
            this.empArr=res.data.result
        })
    },
    computed: {
        scrollerHeight: function() {
            return (window.innerHeight - 90) + 'px';
        },
        divHeight: function() {
            return (window.innerHeight - 185) + 'px';
        },
    },
    components: {
        
    },
    created () {
        if (this.$route.query.form){
            this.v_form =this.$route.query.form
        }
        if (this.v_user){
            //左侧tree data
            this.getData('0')
        }else{
            let routeUrl = this.$router.resolve({
                path: "/",
                query: {lang:this.$store.state.lang,form:this.v_form },
            });
            window.open(routeUrl.href, '_self');
        }                
 
    },
    watch: {
 
    },
    methods: { 
        // 文件权限保存
        fileOk(){
            let vm =this
            let arr=[]
            for (let k=0 ;k<vm.empArr.length;k++){
                let obj={}
                let itmseq=!vm.empArr[k].ITMSEQ?'':vm.empArr[k].ITMSEQ
                Object.assign(obj,{IDSEQ:vm.file_idseq,LSTSEQ:vm.file_lstseq,ITMSEQ:itmseq,USERNO:vm.empArr[k].USERNO,USERSEQ:vm.empArr[k].IDSEQ,
                            ISSEL:vm.empArr[k].ISSEL,  ISDEL:vm.empArr[k].ISDEL}) 
                arr.push(obj)                           
            }
            //console.log(JSON.stringify(arr))
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/batchBodySave2?username='+vm.v_user+'&password='+vm.v_pwd,
                data: {p_json:JSON.stringify(arr), p_table:'HR_FILEPRIV',p_idseq:vm.idseq},
            }).then(res=>{  
                if (res.data.code==='200'){
                    this.fileClick(this.file_pkseq,this.file_path)
                    this.fileModal=false   
                }else{
                    this.promptWin=true
                    this.prompt=res.data.result
                }
            }) 
            
        },
        fileExit(){
            this.fileClick(this.file_pkseq,this.file_path)
            this.fileModal=false
        },
        //文件权限设定 item.IDSEQ,item.LSTSEQ,item.FILENM,item.ADDUSER,item.ADDDTTM,item.UPDDTTM
        filePrivSet(item){
            if (item.USERNO===this.v_user){
                this.file_Name=item.FILENM //文件名称
                this.file_Adduser=item.ADDUSER  //创建 用户
                this.file_Adddttm=item.ADDDTTM  //创建 日期
                this.file_Upddttm=item.UPDDTTM  //更新日期
                this.file_pkseq=item.PKSEQ  // IDSEQ||'-'||LSTSEQ
                this.file_path=item.FILEPATH  //文件路径
                this.file_idseq=item.IDSEQ  //PK 
                this.file_lstseq=item.LSTSEQ //PK
                this.fileModal =true
                //文件用户权限
                getBaseData(this.$store.state.nologinUrl, 'DIRSEQ',item.IDSEQ,'LSTSEQ',item.LSTSEQ,'V_HR_FILEPRIV','').then(res=>{
                    if (res.data.result.length>0){
                        this.empArr=res.data.result
                    }else{
                        for(let k=0; k<this.empArr.length; k++){
                            this.empArr[k].ISDEL= 'N'
                            this.empArr[k].ISSEL= 'N'
                        }
                    }
                })
            }else{
                this.promptWin=true
                this.prompt='你不是此文件创建者,没有权限设置'
            }
        },
        //目录权限
        dirPriv(idseq){
            //用户清单
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', idseq,'V_HR_DOCPRIV','').then(res=>{
                this.dirArr=res.data.result
            })
        },
        //目录权限保存 
        privOk(){
            let vm =this
            let arr=[]
            for (let k=0 ;k<vm.empArr.length;k++){
                let obj={}
                let lstseq=!vm.empArr[k].LSTSEQ?'':vm.empArr[k].LSTSEQ
                Object.assign(obj,{IDSEQ:vm.idseq,LSTSEQ:lstseq,USERNO:vm.empArr[k].USERNO,USERSEQ:vm.empArr[k].IDSEQ,
                            ISSEL:vm.empArr[k].ISSEL,ISADD:vm.empArr[k].ISADD,ISEDIT:vm.empArr[k].ISEDIT,ISDEL:vm.empArr[k].ISDEL}) 
                arr.push(obj)                           
            }
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/batchBodySave?username='+vm.v_user+'&password='+vm.v_pwd,
                data: {p_json:JSON.stringify(arr), p_table:'HR_DOCPRIV',p_idseq:vm.idseq},
            }).then(res=>{      
                this.setModal=false   
            })             
        },
        //全选事件
        allSel(val){
            if (val==='S') { //浏览
                for(let k=0; k<this.empArr.length; k++){
                    this.empArr[k].ISSEL=this.ISSEL==='Y'?'Y':'N'
                }
            }else if (val==='A') { //新增
                for(let k=0; k<this.empArr.length; k++){
                    this.empArr[k].ISADD=this.ISADD==='Y'?'Y':'N'
                }
            }else if (val==='U') { //编辑
                for(let k=0; k<this.empArr.length; k++){
                    this.empArr[k].ISEDIT=this.ISEDIT==='Y'?'Y':'N'
                }
            }else if (val==='D') { //删除
                for(let k=0; k<this.empArr.length; k++){
                    this.empArr[k].ISDEL=this.ISDEL==='Y'?'Y':'N'
                }
            }
        },
        //设置权限
        setPriv(){
            if( this.idseq!==''){
                this.setModal =true
                //目录用户权限
                getBaseData(this.$store.state.nologinUrl, 'DIRSEQ',this.idseq,'','','V_HR_DOCPRIV','').then(res=>{
                    if (res.data.result.length>0){
                        this.empArr=res.data.result
                    }else{
                        for(let k=0; k<this.empArr.length; k++){
                            this.empArr[k].ISDEL= 'N'
                            this.empArr[k].ISEDIT= 'N'
                            this.empArr[k].ISADD= 'N'
                            this.empArr[k].ISSEL= 'N'
                        }
                    }
                })
            }else{
                this.promptWin=true
                this.prompt='请选择一目录'
            }           
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            console.log(event.percent, file, fileList)
            this.videoFlag = true
            // this.videoUploadPercent = file.percentage.toFixed(0)
            // this.videoUploadPercent = event.percent.toFixed(0)
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //批量下载
        bulkDownload(){
            var that =this
            if (that.downArr.length===0){
                this.promptWin =true
                this.prompt='请选中要下载的文件'
            }else if (that.downArr.length>10){
                this.promptWin =true
                this.prompt='出于考虑下载性能,下载文件数量不能超过10个' 
            }else{
                this.downing =true
                let data =that.downArr //需要下载打包的路径，可以是本地相对路径，也可以是跨域的全路径
                let fileArr=[]
                for (let k=0;k<data.length; k++){
                   // console.log(data[0].slice(data[0].indexOf('/manage')))
                    fileArr.push(data[k].slice(data[k].indexOf('/manage')))
                }
                 
                const zip =new JSZip()
                const cache={}
                const promises =[]
                fileArr.forEach(item=>{
                    const promise =downloadFile(item).then(data=>{ //下载文件，并存成arraybuffer对象
                        const arr_name=item.split("/")
                        const file_name=arr_name[arr_name.length-1] //获取文件名
                        zip.file(file_name,data,{binary:true}) //逐个添加文件
                        cache[file_name] =data
                    })
                    promises.push(promise)
                })
                Promise.all(promises).then(()=>{
                    zip.generateAsync({type:"blob"}).then(content=>{ //生成二进制流
                        FileSaver.saveAs(content,"打包下载.zip") //利用file-saver 保存文件
                        this.downing =false
                    })
                })
                
            }
        },
        //pdf 预览
        mp4Preview(fileUrl){
            //全屏显示
            this.mp4Model =true
            this.playerOptions.sources[0].src =fileUrl 
        },
        pdfPreview(fileUrl){
            //全屏显示
            let routeUrl = this.$router.resolve({
                path: '/fullPreview',
                query: {lang:this.LANG,fileUrl: fileUrl },
            });
            window.open(routeUrl.href, '_blank'); 
        },
        //目录新增、修改保存
        modal_ok(){
            var vm =this
            if (!this.formCol.CNAME){
                this.promptWin =true
                this.prompt='本级目录名称不能为空'
            }else{
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_user+'&password='+this.v_pwd,
                    data: {p_json:JSON.stringify(this.formCol), p_table:'HR_DOC'},
                }).then(res=>{      
                    if(res.data.result =='OK')  {          
                        vm.addModal =false   
                        vm.getData(vm.treeid)
                        // vm.$nextTick(()=>{
                        //     let node = vm.$refs['eltree'].getNode(vm.treeid)
                        //     if (vm.addtype==='edit'){
                        //         node.parent.loaded = false;
                        //         node.parent.expand()  // 主动调用展开节点方法，重新查询该节点下的所有子节点   
                        //     } 
                        // })                        
                    }else{
                        this.promptWin =true
                        this.prompt=res.data.result
                    }        
                })
            }
        } ,
        //新增目录
        addFolder(){
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', this.idseq,'V_HR_DOCPRIV','').then(res=>{
                this.dirArr=res.data.result
                if (this.dirArr.length===0){
                    this.promptWin =true
                    this.prompt='此目录下你没有新增的权限'
                }else{
                    if (this.dirArr[0].ISADD==='N'){
                        this.promptWin =true
                        this.prompt='此目录下你没有新增的权限'
                    }else{
                        this.addModal =true
                        this.addtype ='create'
                        this.formCol ={}
                        Object.assign(this.formCol,{PARENTNO:this.treeid,PARENTNM:this.nodeLabel})
                    }
                }
            })
        } ,  
        //编辑目录
        editFolder(){
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', this.idseq,'V_HR_DOCPRIV','').then(res=>{
                this.dirArr=res.data.result
                if (this.dirArr.length===0){
                    this.promptWin =true
                    this.prompt='你没有编辑此目录的权限'
                }else{
                    if (this.dirArr[0].ISEDIT==='N'){
                        this.promptWin =true
                        this.prompt='你没有编辑此目录的权限'
                    }else{
                        this.addModal =true
                        this.addtype ='edit'
                        this.formCol ={}
                        Object.assign(this.formCol,{PARENTNO:this.parentid,CODE:this.treeid,CNAME:this.nodeLabel})
                    }
                }
            })
        } , 
        //删除目录
        delFolder(){
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', this.idseq,'V_HR_DOCPRIV','').then(res=>{
                this.dirArr=res.data.result
                if (this.dirArr.length===0){
                    this.promptWin =true
                    this.prompt='你没有删除此目录的权限'
                }else{
                    if (this.dirArr[0].ISDEL==='N'){
                        this.promptWin =true
                        this.prompt='你没有删除此目录的权限'
                    }else{
                        this.$Modal.confirm({
                            title: '删除确认',
                            content: '<p>你确定要删除此目录吗</p>',
                            onOk: () => {
                                //发起删除请求
                                var vm=this;
                                this.$axios({
                                    method: 'post',
                                    url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.v_user+'&password='+vm.v_pwd,
                                    data: {p_key:'code',p_value:this.treeid,p_table:'hr_doc'},
                                }).then(res=>{      
                                    if(res.data.code =='200')  {           
                                        vm.getData(vm.parentid)     
                                    }else{
                                        this.promptWin =true
                                        this.prompt=res.data.message
                                    }        
                                }) 
                            }
                        })
                    }
                }
            })
        } , 
        // scaledown 会将图片完整的显示在控件中，
        // 当图片的宽或高大于控件时和contain一样会等比例进行缩放直到完全显示在控件中为止
        //当图片宽和高都小于控件时和 none方式一样，不进行任何缩放，保持原身材居中显示，总结就是只能等比例缩放不能放大
        //本页全选文件
        allSelect(){
            if (this.checkAll ==='Y'){ //全选
                for (let k=0; k<this.fileList.length;k++){
                    this.fileArr.push(this.fileList[k].PKSEQ)
                    this.downArr.push(this.fileList[k].FILEPATH)
                }
            }else{
                this.fileArr=[]
                this.downArr=[]
            }
        },
        //排序方式
        fileSortBy(asc_desc){
            if (this.fileSort==='time') { //时间
                this.getFileList(this.idseq,' order by adddttm '+asc_desc) 
            }else if (this.fileSort==='size') { //大小
                this.getFileList(this.idseq,' order by filesz '+asc_desc) 
            }else if (this.fileSort==='name') { //名字
                this.getFileList(this.idseq,' order by filenm '+asc_desc) 
            }
        },
        //文件查找
        fileSearch(){
            this.srchFileList(this.idseq,this.srchFile )
        },
        //移动文件
        moveSel(data){
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', this.idseq,'V_HR_DOCPRIV','').then(res=>{
            this.dirArr=res.data.result
            if (this.dirArr.length===0){
                this.promptWin =true
                this.prompt='你没有编辑此目录的权限'
            }else{
                if (this.dirArr[0].ISEDIT==='N'){
                        this.promptWin =true
                        this.prompt='你没有编辑此目录的权限'
                    }else{
                        if (this.fileArr.length===0){
                            this.promptWin =true
                            this.prompt='请选中要移动的文件'
                        }else{
                            var vm=this;
                            this.moveVal=data.label
                            let v_cond ='('+JSON.stringify(this.fileArr).replaceAll("\"",'\'').replace('[','').replace(']','')+')'
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'auth/login/updateRec' ,
                                data: {p_key:'idseq',p_value:vm.idseq, p_table:'HR_DOCDTL',p_where:' and PKseq IN '+v_cond,p_cond:' set idseq=(select idseq from hr_doc where code=\''+data.id+'\')'},
                            }).then(res=>{      
                                if(res.data.code =='200')  {    
                                    vm.getFileList(vm.idseq,' order by adddttm ')                       
                                }else{
                                    this.$alert(res.data.result, '提示', {
                                        confirmButtonText: '确定',
                                        showClose:false,
                                        type:'info'
                                    })
                                }        
                            }) 
                            // 隐藏select自带的下拉框
                            vm.$refs.select.blur()
                        }
                    }
                }
            })
        },
        // 批量删除
        delFiles(){
            getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'dirseq', this.idseq,'V_HR_DOCPRIV','').then(res=>{
            this.dirArr=res.data.result
            if (this.dirArr.length===0){
                this.promptWin =true
                this.prompt='你没有删除此目录的权限'
            }else{
                if (this.dirArr[0].ISDEL==='N'){
                    this.promptWin =true
                    this.prompt='你没有删除此目录的权限'
                }else{
                    if(this.fileArr.length===0){
                        this.promptWin =true
                        this.prompt='请选中要删除的文件'
                    }else{
                        let v_cond ='('+JSON.stringify(this.fileArr).replaceAll("\"",'\'').replace('[','').replace(']','')+')'
                        console.log(this.idseq+':'+v_cond)
                        this.$Modal.confirm({
                            title: '删除确认',
                            content: '<p>将文件放入回收站会影响到使用该文件的地方，确定放入？</p>',
                            onOk: () => {
                                //发起删除请求
                                var vm=this;
                                this.$axios({
                                    method: 'post',
                                    url:vm.$store.state.baseUrl+'auth/login/updateRec' ,
                                    data: {p_key:'idseq',p_value:vm.idseq, p_table:'HR_DOCDTL',p_cond:' set stfg=\'X\'',p_where:' and PKseq IN '+v_cond},
                                }).then(res=>{      
                                    if(res.data.code ==='200')  {  
                                        if (vm.idseq ==='38986'){
                                            this.getPubList()
                                        }else{  
                                            vm.getFileList(vm.idseq,' order by adddttm  ')                       
                                        }
                                    }else{
                                        this.$alert(res.data.result, '提示', {
                                            confirmButtonText: '确定',
                                            showClose:false,
                                            type:'info'
                                        })
                                    }        
                                }) 
                            }
                        });
                    }
                }
            }
            })
        },
        //文件点击事件
        fileClick(pkseq,filepath){
            if (this.fileArr.indexOf(pkseq)===-1){
                this.fileArr.push(pkseq)
                this.downArr.push(filepath)
            }else{
                this.fileArr.splice(this.fileArr.indexOf(pkseq),1)
                this.downArr.splice(this.downArr.indexOf(filepath),1)
            }
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {              
            this.fileName= getDate('year')+'-oss'     
            this.uploadData = {
                code: this.fileName ,
                p_user:this.v_user,
                p_idseq:this.idseq ,
                p_table:'hr_docdtl',
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.getFileList(this.idseq,' order by adddttm ') //获得节点下的文件            
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.promptWin =true
            this.prompt=file.name + ' 文件太大, 不能超出20M'
        },
        //文件格式验证错误时的钩子
        handleFormatError (file) {
            this.promptWin =true
            this.prompt='文件格式错误,'+file.name + ' 是不正确, 请选择 jpg or png or pdf or mp4' 
        },
        ascSort(){
            this.sortFlag=false
            this.fileSortBy('asc')
        },
        descSort(){
            this.sortFlag=true
            this.fileSortBy('desc')
        },
        //跳至页数
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //初始化文件页面
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.fileList = this.allData          
            }else{
                this.fileList =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
        //改变 每页数量
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.fileList = this.allData.slice(_start,_end);
        },
        //目录点击事件
        dirClick(id, label,idseq,icon,parentid){
            console.log('id:'+id)
            this.getBread(id,label) //获得节点全路径
            this.getPoint(id) //获得节点下的目录
            this.getFileList( idseq,' order by adddttm  ') //获得节点下的文件
            this.$refs.eltree.setCurrentKey(id)
            this.treeid =id
            this.parentid=parentid
            this.idseq= idseq
            this.lock =icon
        },
        //节点点击
        handleNodeClick(data){
            this.nodeLabel=data.label  //名称
            this.adduser=data.adduser //创建人   
            this.adddttm=data.adddttm //创建时间
            this.upddttm=data.upddttm //修改时间      
            this.treeid =data.id
            this.parentid=data.parentid
            this.idseq=data.idseq
            this.lock =data.icon
            this.getBread(data.id,data.label) //获得节点全路径
            this.getPoint(data.id) //获得节点下的目录
            this.fileArr=[]
            this.downArr=[] //点击目录时把选中清空
            if (data.id==='3'){ //回收站
                this.getBinList()
            }else if (data.id==='2'){ //公共文件
                this.getPubList()
            }else{
                this.getFileList(data.idseq,' order by adddttm  ') //获得节点下的文件
            }
        },
        //获得节点下的目录
        getPoint(id ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'parentno',p_value: id,p_table:'v_hr_doc'}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.dirList =res.data.result
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //获得节点下的文件
        getFileList(idseq,v_sort ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'idseq',p_value: idseq,p_where:' and (userno=\''+this.v_user+'\' or (idseq,lstseq) in (select idseq ,lstseq from hr_filepriv where userno=\''+this.v_user+'\'))',p_key3:'stfg',p_value3:'Y',p_table:'V_HR_DOCDTL',p_cond:v_sort}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    //vm.fileList =res.data.result
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    vm.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //回收文件
        getBinList(){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key:'userno',p_value:this.v_user,p_key2:'stfg',p_value2:'X',p_table:'V_HR_DOCDTL',p_cond:' ORDER BY UPDDTTM DESC'}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    this.promptWin =true
                    this.prompt=res.data.result
                }          
            }) 
        },
        //公共文件
        getPubList(){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key:'stfg',p_value:'Y',p_table:'V_HR_DOCDTL',p_cond:' ORDER BY ADDDTTM DESC'}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    this.promptWin =true
                    this.prompt=res.data.result
                }          
            }) 
        },
        //获得节点全路径
        getBread(id,label){
            var vm =this
            this.pathArr=[]
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'oss/getTreePath?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: id,p_value: label}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    let cnameArr =res.data.result.split(',')
                    let codeArr=res.data.resultcode.split(',')
                    for (let k=0;k<cnameArr.length; k++){
                        console.log(codeArr[k]+":"+cnameArr[k])
                        let obj ={}
                        Object.assign(obj,{'id':codeArr[k],'name':cnameArr[k]})
                        vm.pathArr.push(obj)
                    }
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        //查找文件名
        srchFileList(idseq,name ){
            let vm =this
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/getManyRecord?username='+this.v_user+'&password='+this.v_pwd,
                data: {p_key: 'idseq',p_value: idseq,p_key2:'userno',p_value2:this.v_user,p_key3:'stfg',p_value3:'Y',p_table:'V_HR_DOCDTL',p_cond:' and filenm like \'%'+name+'%\''}
            }).then(function(res){                  
                if(res.data.code ==='200'){
                    vm.allData =res.data.result
                    vm.initPage() 
                }else{
                    vm.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    }) 
                }          
            }) 
        },
        // 获取 树形控件数据
        getData(v_id) {  //' and (code=\'0\' or idseq in (select dirseq from V_HR_DOCPRIV where idseq='+this.v_idseq+' and issel=\'N\'))'
            let vm=this
            vm.loadingtree=true
            vm.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.v_user+'&password='+vm.v_pwd,
                data: {p_table:'V_HR_DOC',p_cond:' order by sortby'}
            }).then(function(res){                  
                vm.treeData =res.data  
                if(res.data.length > 0 ){
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(v_id)
                    })
                }
                vm.loadingtree=false               
            })                              
        },
    }
}
</script>
<style>
    .atooltip{
      border: #1480ce 2px solid !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow::after {
      border-right-color: #1480ce !important;
    }
    .el-tooltip__popper[x-placement^=right] .popper__arrow{
        border-right-color: #1480ce !important;
    }  
   
    .el-checkbox__inner{
        border-color: #5cadff;
    } 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    }  
</style> 
<style scoped lang="less"> 
    .wraper{
        width:1230px;
        margin:1px auto;
    }
    .pageNext{
        margin-bottom:5px;
        overflow: hidden;
        line-height:30px;
        border-left:1px solid #ccc;
        border-right:1px solid #ccc;
        border-bottom:1px solid #ccc;
    }
    .divtext{
        width:90px; 
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行
        height:20px;
        text-align:center;
        line-height:20px;
        margin-bottom:10px;
        margin-left:10px;
 
    }
    .dircss2{
        border:1px solid #5cadff;
        width:90px;
        height:80px;
        display:flex;
        flex-direction:column;
        text-align:center;
        margin-right:18px;
        margin-left:10px;
        margin-top:10px;
        margin-bottom:5px;
    }
    .dircss2:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 1;
        .picsrch{
            right:3px;
            top:1px; 
            position:absolute;
            display:inline;
        }
        .fileset{
            left:3px;
            top:1px; 
            position:absolute;
            display:inline;
        }
    }
    .dircss{
        border:1px solid #ccc;
        width:90px;
        height:80px;
        display:flex;
        flex-direction:column;
        text-align:center;
        margin-right:18px;
        margin-left:10px;
        margin-top:10px;
        margin-bottom:5px;
    }
    .dircss:hover{
        cursor: pointer;
        border:1px solid #409EFF;
        z-index: 1;
        .picsrch{
            right:3px;
            top:1px; 
            position:absolute;
            display:inline;
        }
        .fileset{
            left:3px;
            top:1px;  
            position:absolute;
            display:inline;
        }
    }
    .picsrch{
        right:3px;
        top:1px; 
        position:absolute;
        display:none;
    }
    .fileset{
        left:3px;
        top:1px; 
        position:absolute;
        display:none;
    }
    .selected{
         border-color: red;        
    }
  .selected:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    border-left:2px solid red;
    border-top: 30px solid red;
    border-right: 30px solid transparent;
  }
  .selected:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 8px;
    background: transparent;
    top: 4px;
    left: 1px;
    border: 2px solid white;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-55deg);
    -ms-transform: rotate(-55deg);
    transform: rotate(-55deg);
    z-index: 8;
  }
 
</style>